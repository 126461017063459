// src/components/UnidadeMedidaCreateModal.tsx
import React, { useEffect, useState } from 'react';
import {
    Modal,
    Box,
    Typography,
    Button,
    FormControl,
    FormLabel,
    Input,
    Checkbox,
} from '@mui/joy';
import { UnidadeMedida } from '../types/UnidadeMedida';

interface UnidadeMedidaCreateModalProps {
    open: boolean;
    onClose: () => void;
    onSave: (newUnidade: UnidadeMedida) => void;
}

const UnidadeMedidaCreateModal: React.FC<UnidadeMedidaCreateModalProps> = ({ open, onClose, onSave }) => {
    const [simboloUnidadeMedida, setSimboloUnidadeMedida] = useState<string>('');
    const [nomeUnidadeMedida, setNomeUnidadeMedida] = useState<string>('');
    const [unidadeSi, setUnidadeSi] = useState<boolean>(false);
    const [unidadeDiscreta, setUnidadeDiscreta] = useState<boolean>(false);

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        const unidadeMedida: UnidadeMedida = {
            simbolo: simboloUnidadeMedida,
            nome: nomeUnidadeMedida,
            si: unidadeSi,
            discreta: unidadeDiscreta,
        };
        onSave(unidadeMedida);
    };

    useEffect(() => {
        if (!open) {
            setSimboloUnidadeMedida('');
            setNomeUnidadeMedida('');
            setUnidadeSi(false);
            setUnidadeDiscreta(false);
        }
    }, [open]);

    return (
        <Modal open={open} onClose={onClose}>
            <Box
                component="form"
                onSubmit={handleSubmit}
                sx={{
                    position: 'absolute' as const,
                    top: '50%',
                    borderRadius: 'md',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.body',
                    boxShadow: 24,
                    p: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                }}
            >
                <Typography component="h2">
                    Nova Unidade de Medida
                </Typography>
                <FormControl required>
                    <FormLabel>Simbolo</FormLabel>
                    <Input
                        value={simboloUnidadeMedida}
                        onChange={(e) => setSimboloUnidadeMedida(e.target.value)}
                        placeholder="Ex: kg, m, s, m²"
                    />
                </FormControl>
                <FormControl required>
                    <FormLabel>Nome</FormLabel>
                    <Input
                        value={nomeUnidadeMedida}
                        onChange={(e) => setNomeUnidadeMedida(e.target.value)}
                        placeholder="Ex: kilograma, metro, segundo"
                    />
                </FormControl>
                <FormControl>
                    <Checkbox 
                        checked={unidadeSi}
                        onChange={(e) => setUnidadeSi(e.target.checked)}
                        label="Unidade do SI" 
                        variant="outlined" 
                    />
                </FormControl>
                <FormControl>
                    <Checkbox 
                        checked={unidadeDiscreta}
                        onChange={(e) => setUnidadeDiscreta(e.target.checked)}
                        label="Unidade discreta" 
                        variant="outlined" 
                    />
                </FormControl>

                <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
                    <Button variant="soft" onClick={onClose} type="button">
                        Cancelar
                    </Button>
                    <Button variant="solid" color="success" type="submit">
                        Salvar
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default UnidadeMedidaCreateModal;
