// src/components/UnidadeMedidaDeleteModal.tsx
import React from 'react';
import {
    Modal,
    Box,
    Typography,
    Button,
} from '@mui/joy';

interface UnidadeMedidaDeleteModalProps {
    open: boolean;
    onClose: () => void;
    onConfirm: () => void;
    unidadeMedida: string;
}

const UnidadeMedidaDeleteModal: React.FC<UnidadeMedidaDeleteModalProps> = ({
    open,
    onClose,
    onConfirm,
    unidadeMedida,
}) => {
    return (
        <Modal open={open} onClose={onClose}>
            <Box
                sx={{
                    position: 'absolute' as const,
                    top: '50%',
                    borderRadius: 'md',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.body',
                    boxShadow: 24,
                    p: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                }}
            >
                <Typography component="h2">
                    Confirmar Exclusão
                </Typography>
                <Typography>
                    Tem certeza de que deseja excluir a unidade de medida <strong>{unidadeMedida}</strong>?
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
                    <Button variant="soft" onClick={onClose}>
                        Cancelar
                    </Button>
                    <Button variant="solid" color="danger" onClick={onConfirm}>
                        Excluir
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default UnidadeMedidaDeleteModal;
