// src/components/Header.tsx
import React, { useContext } from 'react';
import { AuthContext } from './AuthProvider';
import { Button, Box } from '@mui/joy';
import { useNavigate } from 'react-router-dom';

const Header: React.FC = () => {
    const auth = useContext(AuthContext);
    const navigate = useNavigate();
    
    const handleLogout = () => {
        auth?.logout();
    };

    return (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2, gap: 3 }}>
            <Button color='neutral' variant="outlined" onClick={() => navigate("/")}>
            Menu
            </Button>
            <Button color='neutral' variant="outlined" onClick={handleLogout}>
            Sair
            </Button>
        </Box>
    );
};

export default Header;
