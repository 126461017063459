// src/components/ProductDeleteModal.tsx
import React from 'react';
import { Modal, Typography, Button, Box } from '@mui/joy';

interface ProductDeleteModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  productName: string;
}

const ProductDeleteModal: React.FC<ProductDeleteModalProps> = ({ open, onClose, onConfirm, productName }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute' as const,
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 300,
          bgcolor: 'background.body',
          borderRadius: 'md',
          boxShadow: 'lg',
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        <Typography>Confirmar Exclusão</Typography>
        <Typography>Tem certeza que deseja excluir o produto <strong>{productName}</strong>?</Typography>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1, mt: 2 }}>
          <Button variant="plain" onClick={onClose}>
            Cancelar
          </Button>
          <Button variant="solid" color="danger" onClick={onConfirm}>
            Excluir
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ProductDeleteModal;
