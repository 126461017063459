// src/pages/LoginPage.tsx
import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../components/AuthProvider';
import api from '../services/api';
import { Box, Button, Input, Typography, Card } from '@mui/joy';

const LoginPage: React.FC = () => {
  const navigate = useNavigate();
  const auth = useContext(AuthContext);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const response = await api.post('/api/admin/login', { username, password });
      auth?.login(response.data.token);
      navigate('/produtos');
    } catch (err) {
      setError('Credenciais inválidas');
    }
  };

  return (
    <Box sx={{ display: 'flex', height: '100vh', alignItems: 'center', justifyContent: 'center' }}>
      <Card variant="outlined" sx={{ width: 400, p: 4 }}>
        <Typography level="h4" component="h1" textAlign="center">
          Login Administrativo
        </Typography>
        <form onSubmit={handleSubmit}>
          <Input
            sx={{ mt: 2 }}
            placeholder="Usuário"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
          <Input
            sx={{ mt: 2 }}
            placeholder="Senha"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          {error && (
            <Typography color="danger" sx={{ mt: 1 }}>
              {error}
            </Typography>
          )}
          <Button sx={{ mt: 2 }} type="submit" fullWidth>
            Entrar
          </Button>
        </form>
      </Card>
    </Box>
  );
};

export default LoginPage;
