// src/pages/ProductsPage.tsx
import React, { useState, useEffect } from 'react';
import api from '../services/api';
import {
    Box,
    Button,
    Input,
    Typography,
    Card,
    List,
    ListItem,
    IconButton,
    Snackbar,
    Accordion,
    AccordionGroup,
    AccordionSummary,
    AccordionDetails,
    Radio,
    Stack,
    Slider,
} from '@mui/joy'; // Usando componentes do @mui/joy
import Header from '../components/Header';
import DeleteIcon from '@mui/icons-material/Delete';
import ProductDeleteModal from '../components/ProductDeleteModal';
import { Produto } from '../types/Produto';
import ProductCreateModal from '../components/ProductCreateModal';

const ProductsPage: React.FC = () => {
    const [produtos, setProdutos] = useState<Produto[]>([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [tipoBuscaSimilaridade, setTipoBuscaSimilaridade] = useState<string>("within-range");
    const kNearestNeighborInputRef = React.useRef<HTMLInputElement | null>(null);
    const [kNearestNeighbor, setKNearestNeighbor] = useState<number>(3);
    const [range, setRange] = useState<number>(0.15);

    // Estados para modal de exclusão
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [productToDelete, setProductToDelete] = useState<Produto | null>(null);

    // Estados para modal de criação
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    // Estado para Snackbar de sucesso
    const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);

    const fetchProdutos = async () => {
        setIsLoading(true);
        try {
            const response = await api.get('/api/admin/produtos');
            setProdutos(response.data);
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSearch = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            if (tipoBuscaSimilaridade === "nearest-neighbors") {
                const responseKNN = await api.post('/api/admin/produtos/nearest-neighbors', {
                    descricaoEAplicacao: searchTerm,
                    numNeighbors: kNearestNeighbor,
                });
                setProdutos(responseKNN.data);
            }
            if (tipoBuscaSimilaridade === "within-range") {
                const responseWR = await api.post('/api/admin/produtos/within-range', {
                    descricaoEAplicacao: searchTerm,
                    range: range,
                });
                setProdutos(responseWR.data);
            }
            
            // Limpa o campo de busca
            setSearchTerm('');
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        fetchProdutos();
    }, []);

    // Funções para exclusão
    const handleOpenDeleteModal = (produto: Produto) => {
        setProductToDelete(produto);
        setIsDeleteModalOpen(true);
    };

    const handleCloseDeleteModal = () => {
        setProductToDelete(null);
        setIsDeleteModalOpen(false);
    };

    const handleConfirmDelete = async () => {
        if (productToDelete) {
            try {
                await api.delete(`/api/admin/produtos/${productToDelete.id}`);
                // Atualiza a lista de produtos removendo o deletado
                setProdutos(produtos.filter((p) => p.id !== productToDelete.id));
                handleCloseDeleteModal();
            } catch (err) {
                console.error(err);
                // Opcional: mostrar uma mensagem de erro para o usuário
            }
        }
    };

    // Funções para criação
    const handleOpenCreateModal = () => {
        setIsCreateModalOpen(true);
    };

    const handleCloseCreateModal = () => {
        setIsCreateModalOpen(false);
    };

    const handleSaveNewProduct = async (newProduct: Produto) => {
        try {
            const response = await api.post('/api/admin/produtos', newProduct);
            handleCloseCreateModal();
            setIsSnackbarOpen(true);
            setProdutos([...produtos, response.data]);
        } catch (err) {
            console.error(err);
            // Opcional: mostrar uma mensagem de erro para o usuário
        }
    };

    const handleCloseSnackbar = () => {
        setIsSnackbarOpen(false);
    };

    const handleChangeTipoBuscaSimilaridade = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTipoBuscaSimilaridade(event.target.value);
    };

    return (
        <>
            <Header />
            <Box sx={{ p: 4 }}>

                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
                    <Button variant="solid" color="success" onClick={handleOpenCreateModal}>
                        Novo Produto
                    </Button>
                </Box>

                <Typography level="h4" component="h1" sx={{ mb: 2 }}>
                    <strong>Catálogo de Produtos</strong>
                </Typography>


                <Stack
                    component="form"
                    onSubmit={handleSearch}
                    sx={{
                        mt: 2,
                        display: 'flex',
                        border: '1px solid',
                        borderColor: 'neutral.300',
                        borderRadius: 21, // Define os cantos arredondados
                        p: 2,             // Padding interno
                    }}
                >
                    <AccordionGroup>
                        <Accordion>
                            <AccordionSummary>Parâmetros de busca</AccordionSummary>
                            <AccordionDetails>
                                <Stack alignItems={"center"} direction={"row"} sx={{ height: "80px" }}>
                                    <Radio
                                        checked={tipoBuscaSimilaridade === "within-range"}
                                        onChange={handleChangeTipoBuscaSimilaridade}
                                        sx={{
                                            width: '300px', // Mantém a largura do Radio menor
                                        }}
                                        name="tipoMedidaSimilaridadeBusca"
                                        value="within-range"
                                        label="Dentro do intervalo"
                                    />
                                    <Slider
                                        disabled={tipoBuscaSimilaridade !== "within-range"}
                                        aria-label="Small steps"
                                        onChange={(e, value) => setRange(value as number)}
                                        value={range}
                                        defaultValue={0.15}
                                        step={0.01}
                                        min={0}
                                        max={2}
                                        valueLabelDisplay="auto"
                                        sx={{ width: '300px' }} 
                                    />
                                </Stack>
                                <Stack alignItems={"center"} direction={"row"} sx={{ height: "80px" }}>
                                    <Radio
                                        checked={tipoBuscaSimilaridade === "nearest-neighbors"}
                                        onChange={handleChangeTipoBuscaSimilaridade}
                                        sx={{
                                            width: '300px', // Mesma largura para alinhar
                                        }}
                                        name="tipoMedidaSimilaridadeBusca"
                                        value="nearest-neighbors"
                                        label="N Vizinhos mais próximos"
                                    />
                                    <Input
                                        disabled={tipoBuscaSimilaridade !== "nearest-neighbors"}
                                        type="number"
                                        onChange={(e) => setKNearestNeighbor(parseInt(e.target.value))}
                                        defaultValue={3}
                                        slotProps={{
                                            input: {
                                                ref: kNearestNeighborInputRef,
                                                min: 1,
                                                max: 100,
                                                step: 1,
                                            },
                                        }}
                                        sx={{ width: '100px' }} // Largura do input mantida pequena
                                    />
                                </Stack>
                            </AccordionDetails>
                        </Accordion>
                    </AccordionGroup>
                    <Box sx={{ mt: 2, display: 'flex', gap: 1 }}>
                        <Input
                            placeholder="Buscar produtos por similaridade..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            fullWidth
                        />
                        <Button color="neutral" variant="soft" type="submit">
                            Buscar
                        </Button>
                    </Box>
                </Stack>

                {isLoading ? (
                    <Typography>Carregando...</Typography>
                ) : (
                    <List sx={{ mt: 4 }}>
                        {produtos.map((produto) => (
                            <ListItem key={produto.id}>
                                <Card
                                    variant="outlined"
                                    sx={{
                                        width: '100%',
                                        p: 2,
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'stretch', // Estica os filhos verticalmente
                                    }}
                                >
                                    {/* Conteúdo do Produto à Esquerda */}
                                    <Box
                                        sx={{
                                            flexGrow: 1,          // Permite que o Box cresça para preencher o espaço restante
                                            flexBasis: 0,         // Necessário para o flexGrow funcionar corretamente
                                            overflow: 'hidden',   // Opcional: evita que o conteúdo transborde
                                        }}
                                    >
                                        <Typography>
                                            <strong>Nome do produto:</strong> {produto.nome || 'N/A'}
                                        </Typography>
                                        <Typography>
                                            <strong>Descrição:</strong> {produto.descricaoEFinalidade || 'N/A'}
                                        </Typography>
                                        <Typography sx={{ mt: 1 }}>
                                            <strong>Unidade de Medida Padrão:</strong> {produto.unidadeMedidaPadrao || 'N/A'}
                                        </Typography>

                                    </Box>

                                    {/* Contêiner para o Botão de Excluir à Direita */}
                                    <Box
                                        sx={{
                                            width: '60px',           // Largura fixa para o botão
                                            flexShrink: 0,           // Impede que o Box encolha
                                            display: 'flex',
                                            alignItems: 'center',    // Centraliza verticalmente
                                            justifyContent: 'center',// Centraliza horizontalmente
                                        }}
                                    >
                                        <IconButton
                                            color="danger"
                                            onClick={() => handleOpenDeleteModal(produto)}
                                            aria-label="Excluir Produto"
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Box>
                                </Card>
                            </ListItem>
                        ))}
                    </List>
                )}

                {/* Modal de Exclusão */}
                {productToDelete && (
                    <ProductDeleteModal
                        open={isDeleteModalOpen}
                        onClose={handleCloseDeleteModal}
                        onConfirm={handleConfirmDelete}
                        productName={productToDelete.nome}
                    />
                )}

                {/* Modal de Criação */}
                <ProductCreateModal
                    open={isCreateModalOpen}
                    onClose={handleCloseCreateModal}
                    onSave={handleSaveNewProduct}
                />

                {/* Snackbar de Sucesso */}
                <Snackbar
                    open={isSnackbarOpen}
                    autoHideDuration={3000}
                    variant="soft"
                    color="success"
                    onClose={handleCloseSnackbar}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                    Produto adicionado com sucesso!
                </Snackbar>
            </Box >
        </>
    );

};

export default ProductsPage;
