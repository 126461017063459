// src/App.tsx
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider } from './components/AuthProvider';
import LoginPage from './pages/LoginPage';
import ProductsPage from './pages/ProductsPage';
import PrivateRoute from './components/PrivateRoute';
import HomePage from './pages/HomePage';
import CategoriasProdutosPage from './pages/CategoriasProdutosPage';
import UnidadesMedidaPage from './pages/UnidadesMedidaPage';

const App: React.FC = () => {
    return (
        <AuthProvider>
            <Router basename='/admin'>
                <Routes>
                    <Route path="/login" element={<LoginPage />} />
                    
                    <Route
                        path="/produtos"
                        element={
                            <PrivateRoute>
                                <ProductsPage />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/"
                        element={
                            <PrivateRoute>
                                <HomePage />
                            </PrivateRoute>
                        } />

                    <Route path="*" element={<Navigate to="/produtos" replace />} />

                    <Route
                        path="/categorias-produtos"
                        element={
                            <PrivateRoute>
                                <CategoriasProdutosPage />
                            </PrivateRoute>
                        } />
                    
                    <Route 
                        path="/unidades-medida"
                        element={
                            <PrivateRoute>
                                <UnidadesMedidaPage />
                            </PrivateRoute>
                        } />
                        
        </Routes>
            </Router>
        </AuthProvider>
    );
};

export default App;
