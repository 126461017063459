import React from "react";
import { Box, Sheet, Typography } from "@mui/joy";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header";

const HomePage: React.FC = () => {
  const navigate = useNavigate();

  const menuItems = [
    { label: "Produtos", path: "/produtos" },
    { label: "Categorias de Produtos", path: "/categorias-produtos" },
    { label: "Unidades de Medida", path: "/unidades-medida" },
  ];

  return (
    <>
      <Header />
      <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '70vh' }}>
        <Box
          sx={{
            p: 4,
            flexGrow: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex", gap: 2 }}>
            {menuItems.map((item) => (
              <Sheet
                key={item.label}
                variant="outlined"
                onClick={() => navigate(item.path)}
                sx={{
                  width: 250,
                  height: 250,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 21,
                  cursor: "pointer",
                  "&:hover": {
                    boxShadow: "md",
                  },
                  "&:active": {
                    boxShadow: "none",
                  },
                }}
              >
                <Typography textAlign="center">
                  {item.label}
                </Typography>
              </Sheet>
            ))}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default HomePage;
