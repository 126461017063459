// src/components/CategoriaCreateModal.tsx
import React, { useState } from 'react';
import {
    Modal,
    Box,
    Typography,
    Button,
    FormControl,
    FormLabel,
    Input,
    Textarea,
} from '@mui/joy';

export interface NewCategoria {
    nome: string;
    descricao?: string;
    // ... outras propriedades conforme necessário
}

interface CategoriaCreateModalProps {
    open: boolean;
    onClose: () => void;
    onSave: (newCategoria: NewCategoria) => void;
}

const CategoriaCreateModal: React.FC<CategoriaCreateModalProps> = ({ open, onClose, onSave }) => {
    const [nome, setNome] = useState('');
    const [descricao, setDescricao] = useState('');

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        onSave({ nome, descricao });
        setNome('');
        setDescricao('');
    };

    return (
        <Modal open={open} onClose={onClose}>
            <Box
                component="form"
                onSubmit={handleSubmit}
                sx={{
                    position: 'absolute' as const,
                    top: '50%',
                    borderRadius: 'md',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.body',
                    boxShadow: 24,
                    p: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                }}
            >
                <Typography component="h2">
                    Nova Categoria
                </Typography>
                <FormControl required>
                    <FormLabel>Nome</FormLabel>
                    <Input
                        value={nome}
                        onChange={(e) => setNome(e.target.value)}
                        placeholder="Nome da categoria"
                    />
                </FormControl>
                <FormControl>
                    <FormLabel>Descrição</FormLabel>
                    <Textarea
                        value={descricao}
                        onChange={(e) => setDescricao(e.target.value)}
                        placeholder="Descrição da categoria"
                        minRows={3}
                    />
                </FormControl>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
                    <Button variant="soft" onClick={onClose} type="button">
                        Cancelar
                    </Button>
                    <Button variant="solid" color="success" type="submit">
                        Salvar
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default CategoriaCreateModal;
