import React, { createContext, useState, useEffect } from 'react';

// Função para verificar se o token JWT é válido (baseado na expiração)
function isTokenValid(token: string): boolean {
    try {
        const payload = JSON.parse(atob(token.split('.')[1])); // Decodifica a parte do payload do JWT
        const currentTime = Math.floor(Date.now() / 1000); // Tempo atual em segundos
        return payload.exp && payload.exp > currentTime; // Verifica se o token não está expirado
    } catch (e) {
        return false; // Caso o token não seja válido ou haja erro na decodificação
    }
}

interface AuthContextType {
    isAuthenticated: boolean;
    login: (token: string) => void;
    logout: () => void;
}

export const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(() => {
        const token = localStorage.getItem("jwt");
        if (isTokenValid(token!)) {
            return true;
        } else {
            return false;
        } 
    });

    // Valida o token quando o app é carregado
    useEffect(() => {
        const token = localStorage.getItem('jwt');
        if (token && isTokenValid(token)) {
            setIsAuthenticated(true);
        } else {
            localStorage.removeItem('jwt'); // Remove token inválido
            setIsAuthenticated(false);
        }
    }, []);

    const login = (token: string) => {
        localStorage.setItem('jwt', token);
        if (isTokenValid(token)) {
            setIsAuthenticated(true);
        } else {
            setIsAuthenticated(false);
        }
    };

    const logout = () => {
        localStorage.removeItem('jwt');
        setIsAuthenticated(false);
    };

    return (
        <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};
