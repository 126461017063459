// src/pages/CategoriasProdutosPage.tsx
import React, { useState, useEffect } from 'react';
import api from '../services/api';
import {
    Box,
    Button,
    Input,
    Typography,
    Card,
    List,
    ListItem,
    IconButton,
    Snackbar,
} from '@mui/joy'; // Usando componentes do @mui/joy
import Header from '../components/Header';
import DeleteIcon from '@mui/icons-material/Delete';
import CategoriaDeleteModal from '../components/CategoriaDeleteModal';
import CategoriaCreateModal, { NewCategoria } from '../components/CategoriaCreateModal'; // Importar interfaces
import { CategoriaProduto } from '../types/CategoriaProduto';

const CategoriasProdutosPage: React.FC = () => {
    const [categorias, setCategorias] = useState<CategoriaProduto[]>([]);
    const [searchTerm, setSearchTerm] = useState('');

    // Estados para modal de exclusão
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [categoriaToDelete, setCategoriaToDelete] = useState<CategoriaProduto | null>(null);

    // Estados para modal de criação
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    // Estado para Snackbar de sucesso
    const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);

    const fetchCategorias = async () => {
        setIsLoading(true);
        try {
            const response = await api.get('/api/admin/categorias-produtos');
            setCategorias(response.data);
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSearch = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            const response = await api.post('/api/admin/categorias-produtos/nearest-neighbors', {
                descricao: searchTerm,
                numNeighbors: 3,
            });
            setCategorias(response.data);
            // Limpa o campo de busca
            setSearchTerm('');
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        fetchCategorias();
    }, []);

    // Funções para exclusão
    const handleOpenDeleteModal = (categoria: CategoriaProduto) => {
        setCategoriaToDelete(categoria);
        setIsDeleteModalOpen(true);
    };

    const handleCloseDeleteModal = () => {
        setCategoriaToDelete(null);
        setIsDeleteModalOpen(false);
    };

    const handleConfirmDelete = async () => {
        if (categoriaToDelete) {
            try {
                await api.delete(`/api/admin/categorias-produtos/${categoriaToDelete.id}`);
                // Atualiza a lista de categorias removendo a deletada
                setCategorias(categorias.filter((c) => c.id !== categoriaToDelete.id));
                handleCloseDeleteModal();
            } catch (err) {
                console.error(err);
                // Opcional: mostrar uma mensagem de erro para o usuário
            }
        }
    };

    // Funções para criação
    const handleOpenCreateModal = () => {
        setIsCreateModalOpen(true);
    };

    const handleCloseCreateModal = () => {
        setIsCreateModalOpen(false);
    };

    const handleSaveNewCategoria = async (newCategoria: NewCategoria) => {
        try {
            const response = await api.post('/api/admin/categorias-produtos', newCategoria);

            // Fecha o modal
            handleCloseCreateModal();

            // Exibe o snackbar de sucesso
            setIsSnackbarOpen(true);

            // Adiciona a nova categoria à lista
            setCategorias([...categorias, response.data]);
        } catch (err) {
            console.error(err);
            // Opcional: mostrar uma mensagem de erro para o usuário
        }
    };

    const handleCloseSnackbar = () => {
        setIsSnackbarOpen(false);
    };

    return (
        <>
            <Header />
            <Box sx={{ p: 4 }}>

                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
                    <Button variant="solid" color="success" onClick={handleOpenCreateModal}>
                        Nova Categoria
                    </Button>
                </Box>

                <Typography level="h4" component="h1" sx={{ mb: 2 }}>
                    <strong>Catálogo de Categorias de Produtos</strong>
                </Typography>

                <Box component="form" onSubmit={handleSearch} sx={{ mt: 2, display: 'flex', gap: 1 }}>
                    <Input
                        placeholder="Buscar categorias por similaridade..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        fullWidth
                    />
                    <Button color="neutral" variant="soft" type="submit">Buscar</Button>
                </Box>
                {isLoading ? (
                    <Typography>Carregando...</Typography>
                ) : (
                    <List sx={{ mt: 4 }}>
                        {categorias.map((categoria) => (
                            <ListItem key={categoria.id}>
                                <Card
                                    variant="outlined"
                                    sx={{
                                        width: '100%',
                                        p: 2,
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'stretch', // Estica os filhos verticalmente
                                    }}
                                >
                                    {/* Conteúdo da Categoria à Esquerda */}
                                    <Box
                                        sx={{
                                            flexGrow: 1,          // Permite que o Box cresça para preencher o espaço restante
                                            flexBasis: 0,         // Necessário para o flexGrow funcionar corretamente
                                            overflow: 'hidden',   // Opcional: evita que o conteúdo transborde
                                        }}
                                    >
                                        <Typography>
                                            <strong>Nome da Categoria:</strong> {categoria.nome || 'N/A'}
                                        </Typography>
                                        {categoria.descricao && (
                                            <Typography sx={{ mt: 1 }}>
                                                <strong>Descrição:</strong> {categoria.descricao}
                                            </Typography>
                                        )}
                                        {/* Adicione outras propriedades conforme necessário */}
                                    </Box>

                                    {/* Contêiner para o Botão de Excluir à Direita */}
                                    <Box
                                        sx={{
                                            width: '60px',           // Largura fixa para o botão
                                            flexShrink: 0,           // Impede que o Box encolha
                                            display: 'flex',
                                            alignItems: 'center',    // Centraliza verticalmente
                                            justifyContent: 'center',// Centraliza horizontalmente
                                        }}
                                    >
                                        <IconButton
                                            color="danger"
                                            onClick={() => handleOpenDeleteModal(categoria)}
                                            aria-label="Excluir Categoria"
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Box>
                                </Card>
                            </ListItem>
                        ))}
                    </List>
                )}

                {/* Modal de Exclusão */}
                {categoriaToDelete && (
                    <CategoriaDeleteModal
                        open={isDeleteModalOpen}
                        onClose={handleCloseDeleteModal}
                        onConfirm={handleConfirmDelete}
                        categoriaName={categoriaToDelete.nome}
                    />
                )}

                {/* Modal de Criação */}
                <CategoriaCreateModal
                    open={isCreateModalOpen}
                    onClose={handleCloseCreateModal}
                    onSave={handleSaveNewCategoria}
                />

                {/* Snackbar de Sucesso */}
                <Snackbar
                    open={isSnackbarOpen}
                    autoHideDuration={3000}
                    variant="soft"
                    color="success"
                    onClose={handleCloseSnackbar}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                    Categoria adicionada com sucesso!
                </Snackbar>
            </Box>
        </>
    );

};

export default CategoriasProdutosPage;
