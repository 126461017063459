// src/components/ProductCreateModal.tsx
import React, { useState, useEffect } from 'react';
import { Modal, Typography, Button, Box, Input, FormControl, FormLabel, Grid, IconButton, Select, Option } from '@mui/joy';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import shortUUID from 'short-uuid'; // Importar short-uuid para gerar o id
import { CategoriaProduto } from '../types/CategoriaProduto';
import api from '../services/api';
import { Produto } from '../types/Produto';
import { UnidadeMedida } from '../types/UnidadeMedida';
import { UnidadeMedidaProduto } from '../types/UnidadeMedidaProduto';

interface ProductCreateModalProps {
    open: boolean;
    onClose: () => void;
    onSave: (product: Produto) => void | Promise<void>; // Permitir funções assíncronas
}

const ProductCreateModal: React.FC<ProductCreateModalProps> = ({ open, onClose, onSave }) => {
    const [id, setId] = useState('');
    const [nome, setNome] = useState('');
    const [descricaoEFinalidade, setDescricaoEFinalidade] = useState('');
    const [categoriaSelecionadaId, setCategoriaSelecionadaId] = useState<string | null>(null);
    const [categoriasProdutos, setCategoriasProdutos] = useState<CategoriaProduto[]>([]);
    const [unidadeMedidaPadrao, setUnidadeMedidaPadrao] = useState('');
    const [unidadesMedidaProduto, setUnidadesMedidaProduto] = useState<UnidadeMedidaProduto[]>([
        { unidadeMedida: '', fatorConversao: 1 },
    ]);

    useEffect(() => {
        try {
            api.get('/api/admin/categorias-produtos').then(response => {
                setCategoriasProdutos(response.data);
            });
        } catch (err) {
            console.error(err);
        }
    }, [])

    // Gerar novo ID curto ao abrir o modal
    useEffect(() => {
        if (open) {
            setId(shortUUID.generate()); // Gera um novo ID
            setNome('');
            setDescricaoEFinalidade('');
            setUnidadeMedidaPadrao('');
            setUnidadesMedidaProduto([{ unidadeMedida: '', fatorConversao: 1 }]);
        }
    }, [open]);

    // Função para atualizar unidades de medida
    const handleUnidadeChange = (index: number, field: 'unidadeMedida' | 'fatorConversao', value: string | number) => {
        const updatedUnidades = unidadesMedidaProduto.map((unidade, i) =>
            i === index ? { ...unidade, [field]: value } : unidade
        );
        setUnidadesMedidaProduto(updatedUnidades);
    };

    // Função para adicionar uma nova unidade de medida
    const handleAddUnidade = () => {
        setUnidadesMedidaProduto([...unidadesMedidaProduto, { unidadeMedida: '', fatorConversao: 1 }]);
    };

    // Função para remover uma unidade de medida
    const handleRemoveUnidade = (index: number) => {
        setUnidadesMedidaProduto(unidadesMedidaProduto.filter((_, i) => i !== index));
    };

    const handleSave = () => {
        if (!categoriaSelecionadaId) {
            return;
        }
        const newProduct: Produto = {
            id, // Inclui o id gerado no objeto
            nome,
            categoriaId: categoriaSelecionadaId,
            descricaoEFinalidade,
            unidadeMedidaPadrao,
            unidadesMedida: unidadesMedidaProduto, // Plural
        };
        onSave(newProduct); // Chama a prop onSave, que pode ser assíncrona
    };

    return (
        <Modal open={open} onClose={onClose}>
            <Box
                sx={{
                    position: 'absolute' as const,
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 500,
                    bgcolor: 'background.body',
                    borderRadius: 'md',
                    boxShadow: 'lg',
                    p: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                }}
            >
                <Typography>Adicionar Novo Produto</Typography>

                {/* Campo de ID gerado automaticamente */}
                <FormControl>
                    <FormLabel>ID do Produto</FormLabel>
                    <Input value={id} readOnly />
                </FormControl>

                <FormControl>
                    <FormLabel>Nome</FormLabel>
                    <Input value={nome} onChange={(e) => setNome(e.target.value)} required />
                </FormControl>
                <FormControl>
                    <FormLabel>Descrição e Finalidade</FormLabel>
                    <Input
                        value={descricaoEFinalidade}
                        onChange={(e) => setDescricaoEFinalidade(e.target.value)}
                        required
                    />
                </FormControl>
                <FormControl>
                    <FormLabel>Unidade de Medida Padrão</FormLabel>
                    <Input
                        value={unidadeMedidaPadrao}
                        onChange={(e) => setUnidadeMedidaPadrao(e.target.value)}
                        required
                    />
                </FormControl>
                <FormControl>
                    <FormLabel>Categoria</FormLabel>
                    <Select 
                        value={categoriaSelecionadaId}
                        onChange={(e, value) => setCategoriaSelecionadaId(value as string)}
                        placeholder="Selecione uma categoria"
                    >
                        {categoriasProdutos.map((categoria, i) => (
                            <Option value={categoria.id} key={i}>
                                {categoria.nome}
                            </Option>
                        ))}
                    </Select>
                </FormControl>

                {/* Seção para adicionar múltiplas unidades de medida */}
                <Typography>Unidades de Medida Adicionais</Typography>
                {unidadesMedidaProduto.map((unidade, index) => (
                    <Grid container spacing={2} key={index}>
                        <Grid xs={5}>
                            <FormControl>
                                <FormLabel>Unidade de Medida</FormLabel>
                                <Input
                                    value={unidade.unidadeMedida}
                                    onChange={(e) => handleUnidadeChange(index, 'unidadeMedida', e.target.value)}
                                    required
                                />
                            </FormControl>
                        </Grid>
                        <Grid xs={5}>
                            <FormControl>
                                <FormLabel>Fator de Conversão</FormLabel>
                                <Input
                                    type="number"
                                    value={unidade.fatorConversao}
                                    onChange={(e) => handleUnidadeChange(index, 'fatorConversao', Number(e.target.value))}
                                    required
                                />
                            </FormControl>
                        </Grid>
                        <Grid xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                            <IconButton onClick={() => handleRemoveUnidade(index)} disabled={unidadesMedidaProduto.length === 1}>
                                <RemoveCircleIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                ))}
                <Button color="neutral" variant='outlined' onClick={handleAddUnidade}>
                    Adicionar
                </Button>

                <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1, mt: 2 }}>
                    <Button color="neutral" variant="plain" onClick={onClose}>
                        Cancelar
                    </Button>
                    <Button color="neutral" variant="solid" onClick={handleSave}>
                        Salvar
                    </Button>
                </Box>
            </Box>
        </Modal>
    );

};

export default ProductCreateModal;
                                                                                                                        