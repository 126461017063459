// src/pages/UnidadesMedidaPage.tsx
import React, { useState, useEffect } from 'react';
import api from '../services/api';
import {
    Box,
    Button,
    Input,
    Typography,
    Card,
    List,
    ListItem,
    IconButton,
    Snackbar,
} from '@mui/joy'; // Usando componentes do @mui/joy
import Header from '../components/Header';
import DeleteIcon from '@mui/icons-material/Delete';
import UnidadeMedidaDeleteModal from '../components/UnidadeMedidaDeleteModal';
import UnidadeMedidaCreateModal from '../components/UnidadeMedidaCreateModal';
import { UnidadeMedida } from '../types/UnidadeMedida';

const UnidadesMedidaPage: React.FC = () => {
    const [unidades, setUnidades] = useState<UnidadeMedida[]>([]);
    const [searchTerm, setSearchTerm] = useState('');

    // Estados para modal de exclusão
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [unidadeToDelete, setUnidadeToDelete] = useState<UnidadeMedida | null>(null);

    // Estados para modal de criação
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    // Estado para Snackbar de sucesso
    const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);

    // Função para buscar unidades de medida
    const fetchUnidades = async () => {
        setIsLoading(true);
        try {
            const response = await api.get('/api/admin/unidades-medida');
            setUnidades(response.data);
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    };

    // Função de busca (ajuste conforme necessário)
    const handleSearch = async (e: React.FormEvent) => {
        e.preventDefault();
        // Implementar a lógica de busca se necessário
    };

    useEffect(() => {
        fetchUnidades();
    }, []);

    // Funções para exclusão
    const handleOpenDeleteModal = (unidade: UnidadeMedida) => {
        setUnidadeToDelete(unidade);
        setIsDeleteModalOpen(true);
    };

    const handleCloseDeleteModal = () => {
        setUnidadeToDelete(null);
        setIsDeleteModalOpen(false);
    };

    const handleConfirmDelete = async () => {
        if (unidadeToDelete) {
            try {
                await api.delete(`/api/admin/unidades-medida/${encodeURIComponent(unidadeToDelete.simbolo)}`);
                // Atualiza a lista de unidades removendo a deletada
                setUnidades(unidades.filter((u) => u.simbolo !== unidadeToDelete.simbolo));
                handleCloseDeleteModal();
            } catch (err) {
                console.error(err);

            }
        }
    };

    // Funções para criação
    const handleOpenCreateModal = () => {
        setIsCreateModalOpen(true);
    };

    const handleCloseCreateModal = () => {
        setIsCreateModalOpen(false);
    };

    const handleSaveNewUnidade = async (newUnidade: UnidadeMedida) => {
        try {
            const response = await api.post('/api/admin/unidades-medida', newUnidade);

            // Fecha o modal
            handleCloseCreateModal();

            // Exibe o snackbar de sucesso
            setIsSnackbarOpen(true);

            // Adiciona a nova unidade à lista
            setUnidades([...unidades, response.data]);
        } catch (err) {
            console.error(err);
            // Opcional: mostrar uma mensagem de erro para o usuário
        }
    };

    const handleCloseSnackbar = () => {
        setIsSnackbarOpen(false);
    };

    return (
        <>
            <Header />
            <Box sx={{ p: 4 }}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
                    <Button variant="solid" color="success" onClick={handleOpenCreateModal}>
                        Nova Unidade de Medida
                    </Button>
                </Box>

                <Typography level="h4" component="h1" sx={{ mb: 2 }}>
                    <strong>Unidades de Medida</strong>
                </Typography>

                {/* Se necessário, implemente o campo de busca */}
                <Box component="form" onSubmit={handleSearch} sx={{ mt: 2, display: 'flex', gap: 1 }}>
                    <Input
                        placeholder="Buscar unidades..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        fullWidth
                    />
                    <Button color="neutral" variant="soft" type="submit">Buscar</Button>
                </Box>

                {isLoading ? (
                    <Typography>Carregando...</Typography>
                ) : (
                    <List sx={{ mt: 4 }}>
                        {unidades.map((unidade) => (
                            <ListItem key={unidade.simbolo}>
                                <Card
                                    variant="outlined"
                                    sx={{
                                        width: '100%',
                                        p: 2,
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'stretch',
                                    }}
                                >
                                    {/* Conteúdo da Unidade à Esquerda */}
                                    <Box
                                        sx={{
                                            flexGrow: 1,
                                            flexBasis: 0,
                                            overflow: 'hidden',
                                        }}
                                    >
                                        <Typography>
                                            <strong>Simbolo:</strong> {unidade.simbolo}
                                        </Typography>
                                        <Typography sx={{ mt: 1 }}>
                                            <strong>Nome:</strong> {unidade.nome}
                                        </Typography>
                                        <Typography sx={{ mt: 1 }}>
                                            <strong>Unidade do SI:</strong> {unidade.si ? 'Sim' : 'Não'}
                                        </Typography>
                                        <Typography sx={{ mt: 1 }}>
                                            <strong>Unidade discreta:</strong> {unidade.discreta ? 'Sim' : 'Não'}
                                        </Typography>
                                    </Box>

                                    <Box
                                        sx={{
                                            width: '60px',
                                            flexShrink: 0,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <IconButton
                                            color="danger"
                                            onClick={() => handleOpenDeleteModal(unidade)}
                                            aria-label="Excluir Unidade"
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Box>
                                </Card>
                            </ListItem>
                        ))}
                    </List>
                )}

                {/* Modal de Exclusão */}
                {unidadeToDelete && (
                    <UnidadeMedidaDeleteModal
                        open={isDeleteModalOpen}
                        onClose={handleCloseDeleteModal}
                        onConfirm={handleConfirmDelete}
                        unidadeMedida={unidadeToDelete.simbolo}
                    />
                )}

                {/* Modal de Criação */}
                <UnidadeMedidaCreateModal
                    open={isCreateModalOpen}
                    onClose={handleCloseCreateModal}
                    onSave={handleSaveNewUnidade}
                />

                {/* Snackbar de Sucesso */}
                <Snackbar
                    open={isSnackbarOpen}
                    autoHideDuration={3000}
                    variant="soft"
                    color="success"
                    onClose={handleCloseSnackbar}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                    Unidade de medida adicionada com sucesso!
                </Snackbar>
            </Box>
        </>
    );
};

export default UnidadesMedidaPage;
